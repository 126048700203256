import React from 'react';

const NotFound: React.FC = () => (
  <div>
    <h1>404 - Not Found</h1>
    <p>The requested resource could not be found.</p>
  </div>
);

export default NotFound;
