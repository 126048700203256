// src/components/Navbar.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Ensure this CSS file exists

const Navbar: React.FC = () => (
  <nav className="navbar">
    <ul className="navbar-links">
      <li>
        <NavLink
          to="/"
          end
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Home
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/skills"
          end
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Skills
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/experience"
          end
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Experience
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/contact"
          end
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Ping Me!
        </NavLink>
      </li>
      {/* Add more links as needed */}
    </ul>
  </nav>
);

export default Navbar;
